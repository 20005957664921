
import React, { Component } from 'react';
import Select from './common/select';
import moment from 'moment';
import JOI from "joi-browser";

import { HTTPService } from "./../services/httpService";
import { SelectCustomerVehicle } from './partial/selectCustomerVehicle';

const API = new HTTPService();


export default class VehicleSelectForm extends Component {

    state = {
        data: { vin: " " },
        errors: {},
        years : [] ,
        selectedYear : "Year",
        makes : [] ,
        selectedMake : "Make",
        models : [] ,
        selectedModel : "Model",
        trims : [] ,
        selectedTrim : "Trim",
        bodies : [] ,
        selectedBody : "Style",
        selectedStock : "Stock #",
        selectedSize : "",
        selectedId : "",
        errors : {},
        message : "",
        vehicle : "",
        custVehicles : [],
        showList : false,
        spec : "",
        loading : false
        }

    
        schema = {
            vin: JOI.string().regex(/[A-HJ-NPR-Z0-9]{17}/).label('VIN #')
        };
    
// this will return the services offered

    async componentDidMount() {
        // populate years from current year + 1 back to 1950
        const end = new moment().add(1,'years').format("YYYY");
        const start = 1950;
        var years = this.fillRange(start, end);
        years.sort((a,b)=>b-a);
        years.unshift("Year");
        this.setState( {years});
        };

    
        async componentDidUpdate(prevProps) {
//            console.log("Vehicle : prevProps and props :", prevProps.customer, this.props.customer)

            if ((this.props.customer !== prevProps.customer) || (this.props.customerType !== prevProps.customerType)){
 //               console.log ("Customer Type :", this.props.customerType);
//                console.log ("Installer :", this.props.installer);
    
            var vehiclesRequest =  this.props.customer + "/" + this.props.installer.Installer_id ;
    
            // Fetch the vehicles
           const { data: custVehicles } = await API.get(`/vehicles/${vehiclesRequest}`);
           this.setState({ custVehicles});
           if (this.state.custVehicles.length > 0 && this.props.customerType === 'retail') 
           this.setState({ showList : true});

            }
        };
    validate = () => {
        const options = { abortEarly: false };
        const { error } = JOI.validate(this.state.data, this.schema, options);
        if (!error) return null;

        const errors = {};
        for (let item of error.details) errors[item.path[0]] = item.message;
        return errors;
    };

    validateProperty = ({ name, value }) => {
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        const { error } = JOI.validate(obj, schema);
        return error ? error.details[0].message : null;
    };

    handleChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;

        this.setState({ data, errors });
    };


    readVIN = async (vin) => {
        let vinRequest = "/vehiclesVIN/"+vin;        
        const  { data : spec }  =  await API.get(vinRequest);
        this.setState({ spec });  
        return spec;
    }
            
    readMakes = async (yearSelect) => {
            console.log("Here's the year we are looking for :", yearSelect);
            let makesRequest = "/makes/"+yearSelect;        
            const  { data : makes }  =  await API.get(makesRequest);
            this.setState({ makes });  
        // if there is a default selection set for the dealership, select it    
            if (this.props.default){
                const fakeEvent = {
                    target: { value: this.props.default }
                  };                 
                  this.handleMakeChange(fakeEvent);
            }
        }
                
    readModels = async (makeId) => {
        console.log("Here's the make we are looking for :", makeId);
        let vehicleRequest = this.state.selectedYear+makeId[0].id_car_make;
        let modelsRequest = "/models/"+vehicleRequest;        
        const  { data : models }  =  await API.get(modelsRequest);
        this.setState({ models });  
        }
    
    readTrims = async (modelId) => {
        console.log("Here's the model we are looking for :", modelId);
        let trimRequest = modelId[0].id_car_model;
        let trimsRequest = "/trims/"+trimRequest;        
        const  { data : trims }  =  await API.get(trimsRequest);
        this.setState({ trims });  
        }
            
    fillRange = (start, end) => 
        [...Array(end - start + 1)].map((item, index) => start + index);

    handleChange = ({ currentTarget: input }) => {
        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if (errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;

        this.setState({ data, errors });
    };

    handleYearChange = (event) => {
        const { value } = event.target;      
//            console.log("Selected year :", value);
        this.setState( {selectedYear : value, models : [], trims : [] });
        this.readMakes(value);
    }
        
    handleMakeChange = (event) => {
        const { value } = event.target;      
  //        console.log("Selected Make :", value);
        // When make changes, read the new model list from the database and reset the trims
        const dispModel = this.state.makes.filter(make => make.name === value);
        this.readModels(dispModel);
        this.setState( {selectedMake : value, selectedTrim : "Trim", trims : []});
    }

    handleModelChange = (event) => {
        const { value } = event.target;      
        // When model changes, read the new trim list from the database
  //          console.log("Selected model :", value);
    const dispTrim = this.state.models.filter(model => model.name === value);
        this.readTrims(dispTrim);
        this.setState( { selectedModel : value, selectedSize : dispTrim[0].vehicleSize} );
    }
    
    handleTrimChange = (event) => {
        const { value } = event.target;      
        console.log("Selected Trim: ", value)
        this.setState( {selectedTrim : value});
    }
        
    normalizeString = (str) => {
        return str
          .toLowerCase()                // Convert to lowercase
          .replace(/[^a-z0-9]/g, '')      // Remove non-alphanumeric characters
          .trim();                      // Remove any whitespace at start/end
      }
    
      handleClose = () => {
        this.setState( {showList : false});
        }

    doSubmit = async () => {
            // Go and get the vehicle information from NTHSA
        await this.getVIN(this.state.data.vin);
    };

    async getVIN(vinId) {
        // check the vehicle database first
        // if the car isn't in there, then make the request to NHSTANSA
        const { data: vehicle } = await API.get(`/vin/?Id=${vinId}`);

        this.setState({ vehicle: vehicle.Results[0] });
        // need to handle the case where this isn't available
        console.log ("Here's what we got from NHSTA :", this.state.vehicle);
        const { Make,Model,ModelYear } = this.state.vehicle;
        this.setState({ selectedYear : ModelYear});
        
        // here we need to populate the same structure as the retrieved item from the db for selection 
        // and do the same thing as HandleSelect but there will be case clashes 

        await this.readMakes(ModelYear);
        console.log("getVIN : makes for the year: ", ModelYear, this.state.makes);
        
        // now find the make and get the id number
        const searchMake = this.normalizeString(Make);
        const filteredMakes = this.state.makes.filter(item => {
            const fieldValue = this.normalizeString(item.name);
            return fieldValue === searchMake;
        });
        console.log("getVIN : models for the make & year: ", Make, filteredMakes);
        this.setState({ selectedMake : filteredMakes[0].name});
        
        await this.readModels(filteredMakes)
      
        const searchModel = this.normalizeString(Model);
        const filteredModels = this.state.models.filter(item => {
            const fieldValue = this.normalizeString(item.name);
            return fieldValue === searchModel;
        });
        console.log("getVIN : trims for the make & year: ", Model, filteredModels);
        this.setState({ selectedModel : filteredModels[0].name});
        
        await this.readTrims(filteredModels)
            
    };

    
    handleSelect = async (vehicleSelected) => {
        // Set the selected vehicle and select the values in the dropdowns 
        // take the vin and go get the id's for the tables
        console.log ("HandleSelect : selectedVehicle :", vehicleSelected);

        const spec = await this.readVIN(vehicleSelected.vin);

 //           console.log ("HandleSelect : spec :", spec);

            this.readMakes(vehicleSelected.Year);
            this.readModels(spec);
            this.readTrims(spec);
            this.setState( {showList : false});        

        var vehicle = {
            id : vehicleSelected.Vehicle_ID,
            year : vehicleSelected.Year,
            make : vehicleSelected.Make,
            model : vehicleSelected.Model,
            trim : vehicleSelected.Trim,
            body : vehicleSelected.body,
            color : "", 
            series : "",
            vin : vehicleSelected.VIN,
            stock : vehicleSelected.stock,
            size : vehicleSelected.size,
            img_url : ""
          }
          console.log ("HandleSelect : vehicle :", vehicle);

        this.setState( {vehicle , selectedYear : vehicleSelected.Year, selectedMake : vehicleSelected.Make, selectedModel : vehicleSelected.Model, selectedTrim : vehicleSelected.Trim, selectedBody : vehicleSelected.body})            
        this.props.handleVIN(vehicle);
    }
    
    handleBodyChange = (event) => {
        const { value } = event.target;      
        this.setState( {selectedBody : value });
        // make sure to set the size as this is needed for the service catalog
        let size = this.state.trims.filter(trim => trim.name === this.state.selectedTrim);
        console.log("Selected Trim SIZE: ", size)
        this.setState( { selectedSize : size[0].id_car_type} );
        
        // When body style changes, set the selected vehicle
        var vehicle = {
            id : "",
            year : this.state.selectedYear,
            make : this.state.selectedMake,
            model : this.state.selectedModel,
            trim : this.state.selectedTrim,
            body : value,
            color : "", 
            series : "",
            vin : "",
            stock : this.state.selectedStock,
            size : size[0].id_car_type,
            img_url : ""
          }
        if (this.state.data.vin !== " "){
            vehicle.vin = this.state.data.vin
        } else {
            var vinSub = new moment().format("YYYYMMDDHHMMSS");
            vehicle.vin = "NV"+vinSub;
        }
//        console.log ("Handle Body Change : Vehicle record",vehicle);
        this.setState({vehicle});
        this.props.handleVIN(vehicle);

    }

    render() {  
//        console.log("Vehicle Selection : customer Vehicles :", this.state.custVehicles);

        
        // make sure to only include the makes that are applicable
        var makeList = this.state.makes.map(a=>a.name);
        makeList.unshift("Make");
        // make sure to only include the makes that are applicable
//        console.log ("Here are the models : ", this.state.models);
        var modelList = this.state.models.map(a=>a.name);
        modelList.unshift("Model");
        // make sure to only include the makes that are applicable
        var trimList = [...new Set(this.state.trims.map((item) => item.name))];
//        console.log ("This is the trimlist : ", this.state.trims);
        var bodyList = [...new Set(this.state.trims.map((item) => item.body_style))];

        trimList.unshift("Trim");
        bodyList.unshift("Style")
        const { loading } = this.state;


        if ((this.props.currentStep === 2 && this.props.userType === "installer") || (this.props.currentStep === 2 && this.props.userType === "dealer"))
        {
        return (  
            <React.Fragment>
                <form className="bg-request p-5">
                <div className="form-row justify-content-md-center">
                    <h6 className="text-center">Enter a VIN to populate fields or choose manually below</h6>
                </div>
                <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-3">
                            <input type='text' className="form-control" name='vin' value={this.state.data.vin} onChange={this.handleChange} error={this.state.errors['vin']} />
                            {this.state.errors['vin'] && <div className="alert alert-danger">{this.state.errors['vin']}</div>}
                        </div>
                        <div className="form-group col-md-2">
                            <button type="button" className="btn btn-block btn-success buttons" onClick={this.doSubmit} disabled={this.validate()}>
                            {loading && <span>
                                        <i
                                        className="fa fa-refresh fa-spin"
                                        />
                                    </span>}
                                    {!loading && <span>Decode VIN</span>}
                                </button>
                        </div>
                    </div>
                    <div className="form-row justify-content-md-center">
                        <div className="form-group col-md-2">
                        <Select 
                            name="years"
                            label=" "
                            options={this.state.years}
                            value={this.state.selectedYear}
                            onChange={this.handleYearChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                        <Select 
                            name="makes"
                            label=" "
                            options={makeList}
                            value={this.state.selectedMake}
                            onChange={this.handleMakeChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                        <Select 
                            name="models"
                            label=" "
                            options={modelList}
                            value={this.state.selectedModel}
                            onChange={this.handleModelChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                        <Select 
                            name="trims"
                            label=" "
                            options={trimList}
                            value={this.state.selectedTrim}
                            onChange={this.handleTrimChange}
                            />
                        </div>
                        <div className="form-group col-md-2">
                        <Select 
                            name="body"
                            label=" "
                            options={bodyList}
                            value={this.state.selectedBody}
                            onChange={this.handleBodyChange}
                            />
                        </div>
                    </div>
                    <div className="form-row justify-content-md-center">
                        <h6 className="text-center">All fields must be selected to continue</h6>
                    </div>

                    </form>
                {this.state.selectedBody !== "Style" && <div><h5 className="text-center bold p-5">Vehicle Selected</h5>
                <div className="col-lg-12 bold text-center">
                    <p>
                    {this.state.selectedYear} {this.state.selectedMake} {this.state.selectedModel} {this.state.selectedTrim} {this.state.selectedBody}
                    </p>
                </div></div>}
                <SelectCustomerVehicle
                    showList = {this.state.showList}
                    onHide = {this.handleClose}
                    vehicles = {this.state.custVehicles}
                    onSelect = {this.handleSelect}
                />
            </React.Fragment>
        )
    } else {
    return null

    }
    }
}
