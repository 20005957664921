import React, { Component } from 'react';
import { Modal, Button, Row, Col, Form } from 'react-bootstrap';

export class AddPO extends Component {
  state = {
    purchaseOrder: this.props.purchaseOrder || '',
    checkNumber: ''
  };

  // Update state on input change for both fields
  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // For dealer users: submit the PO number only
  handleDealerSubmit = (event) => {
    event.preventDefault();
    const { purchaseOrder } = this.state;
    this.props.onPO(purchaseOrder);
  };

  // For installer users: submit the invoice with PO and/or Check number
  handleInstallerSubmit = (event) => {
    event.preventDefault();
    const { purchaseOrder, checkNumber } = this.state;
    this.props.onInvoice(this.props.requestId, purchaseOrder, checkNumber);
  };

  render() {
    const { type, purchaseOrder } = this.props;
    const { purchaseOrder: statePO, checkNumber } = this.state;
    
    // Set modal title based on user type and whether a PO exists already
    let title = "Enter the PO #";
    if (type === "installer") {
      title = purchaseOrder
        ? "Enter the check # and close out invoice"
        : "Enter the PO and/or check # and close out invoice";
    }

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <Row>
              <Col sm={6}>
                <Form>
                  <Form.Group controlId="PurchaseOrder">
                    <Form.Label>PO #</Form.Label>
                    { !purchaseOrder ? (
                      <Form.Control
                        type="text"
                        name="purchaseOrder"
                        value={statePO}
                        onChange={this.handleChange}
                        required
                        placeholder="This will store with the invoice"
                      />
                    ) : (
                      <div>{purchaseOrder}</div>
                    )}
                  </Form.Group>

                  {type === "installer" && (
                    <Form.Group controlId="CheckNumber">
                      <Form.Label>Check #</Form.Label>
                      <Form.Control
                        type="text"
                        name="checkNumber"
                        value={checkNumber}
                        onChange={this.handleChange}
                        placeholder="This will store with the payment"
                      />
                    </Form.Group>
                  )}

                  <Form.Group>
                    {type === "installer" ? (
                      <Button
                        variant="primary"
                        onClick={this.handleInstallerSubmit}
                        className="buttons"
                      >
                        Close Dealer Invoice
                      </Button>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={this.handleDealerSubmit}
                        className="buttons"
                      >
                        Add PO
                      </Button>
                    )}
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}