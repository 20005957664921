// This will be the header unit for the wizard layout.
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import VehicleSelectForm from './../../vehicleSelection';
import ServiceForm from './../../serviceForm';
import NotesForm from './../../notesForm';
import DatesForm from './../../dateWalkForm';
import CustomerForm from './../../customerForm';
import { HTTPService } from "./../../../services/httpService";
import { convertToUTC } from '../../../util/convert_timezone';
import ReactGA from 'react-ga4';
import _ from 'lodash';

const API = new HTTPService();

class WalkinForm extends Component {

  constructor(props) {

    super(props);

    this.state = {
      redirect: false,
      currentStep: 1,
      platformFee : 0.05,
      customer: "",
      customerName:"",
      customerType:"",
      vehicle: '',
      services: [],
      total : 0,
      discount : 0,
      fee : 0,
      loading:false,
      notes:"",
      taxRate : 0,
      taxPercent : 0,
      availableDate: new moment().startOf('day'),
      dueDate: new moment().startOf('day'),
      newVehicleId: {},
      newRequestId: {},
      user: this.props.user,
      excludeDays : [],
      dealerships : [],
      authenticated : 0,
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleServices = this.handleServices.bind(this);
    this.handleCustomer = this.handleCustomer.bind(this);
    this.handleVIN = this.handleVIN.bind(this);
    this.handleNotes = this.handleNotes.bind(this);
    this.handleDueDate = this.handleDueDate.bind(this);
    this.handleAvailableDate = this.handleAvailableDate.bind(this);
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);

  }

  async componentDidMount() {
    let installerRequest = "/installerLocations/"+this.props.typeId;        
    const  { data : installer }  =  await API.get(installerRequest);
   
    const firstOne = installer.shift();
    this.setState({ installer : firstOne });  
//    console.log("Installer : ",this.state.installer);
    let taxRequest = "/taxes/"+this.state.installer.stripe_tax_rate;        
    const  { data : taxRate }  =  await API.get(taxRequest);
    this.setState({ taxPercent : taxRate.percentage });    
          //read the installer calendar to manage the bookings
      
          let calendarRequest = "/dealerCalendars/"+this.props.typeId;
        
          const  { data : dealerCalendarDays }  = await API.get(calendarRequest);
        
          calendarRequest = "/installerCalendars/"+this.props.typeId;
        
          const  { data : installerCalendarDays }  = await API.get(calendarRequest);
        
          var calendarList = dealerCalendarDays.concat(installerCalendarDays);
        
          var calendarDays = _.orderBy(calendarList, ['workDate'],['asc']);
          this.setState({ calendarDays });
        
          // Create the array of the booked calendar services in the upcoming 60 days
        
          if (calendarDays.length > 0){
            const startDate = moment.utc().format("YYYY-MM-DD");
                
 //           console.log("CC - start Date", startDate);
            var totalDuration = 60;
            
            // caalendarInWindow
            var bookedArray = calendarDays.filter(day => moment.utc(day.workDate).diff(startDate) >= 0);
        
   //         console.log( "Upcoming calendar : ", bookedArray);    
   //         console.log("CC - start Date", startDate);
   //         console.log("Installer :", this.state.installer);
            
            //capacityCalendar
            var bookedData = new Array(totalDuration);
        
            for (var m=0; m < totalDuration; m++) {
              bookedData[m] = {'x' : m, 'exDate' : moment.utc(startDate).add(m,"days").format("YYYY-MM-DD") ,'y' : 0};
          };  
        
          // Need to build the array of booked capacity here for the next 60 days
          for (var k=0; k < bookedData.length; k++ ){
            // for every day in the calendar days, add up the daily_work and assign it to the right day
            let matchingWork = bookedArray.filter(work => moment.utc(work.workDate).format("YYYY-MM-DD") === bookedData[k].exDate);
            let dayTotal = matchingWork.reduce( function (result, value) {
              return result + value.capacity 
            } , 0) 
            bookedData[k].y = dayTotal;
          };
    
          let exclude = [];
   //       console.log ("Booked data length :", bookedData.length)
          for (let b=0; b< bookedData.length; b++){
            // this should be the capacity of the installer vs the size of job
            if (bookedData[b].y >= this.state.installer.capacity * 2){
   //           console.log ("Exclude :", new Date(moment(bookedData[b].exDate).format("MMMM D, YYYY")));
              exclude.push(new Date(moment(bookedData[b].exDate).format("MMMM D, YYYY")));
            }
        }
    
          this.setState( { excludeDays : exclude, bookedDays : bookedData});
    //      console.log("Booked Array :", bookedData);
    //      console.log("Excluded dates :", exclude)    
          };
    // read the dealerships to populate the dealer table      
          let dealerRequest = "/dealers";        
          const  { data : dealerships }  =  await API.get(dealerRequest);
          this.setState( {dealerships});

      //check if authenticated 
      await this.readAuth(this.props.typeId);

  };


  handleSubmit = async e => {
    e.preventDefault();
// add the vehicle to the vehicle table
// need vin, {year, make, model & trim, size and color} for Vehicle in Props
  this.setState({ loading: true });

  console.log ("HandleSubmit : Vehicle : ", this.state.vehicle);
if (!this.state.vehicle.id){
    console.log ("Handle Submit : Adding vehicle");
    const newVehicle = this.addVehicle();
};
//   console.log("New vehicle Added is :" + newVehicle);

// add the requestimate to the request table 
    const newRequest = await this.addRequest();
//    console.log("New Request Added is :" + newRequest);
// add the services records
// need the request number from the previous call to make this work.
// same thing with vehicle

 
//      console.log(newRequest);

      this.addNote(newRequest);
      let aservice = await this.addServices(newRequest);

      let acalendar = await this.addCalendar();

// add the notes here too, as you need the requestId
      this.setState({ loading: false });

// if the services have been added, we now need to add the records to the installer calendar
        if (this.state.customerType !== "whole") {
            alert("Adding the estimate to your calendar"); 
            window.location = "/requestimates";
            ReactGA.event({category: 'Installer', action: 'Created Estimate', label: 'prod'});
            return;  
        } else {
          if (this.state.authenticated){
              await this.addAppointment(newRequest);
          };
          alert("Adding this dealer work to your calendar"); 
          window.location = "/jobs";
          ReactGA.event({category: 'Installer', action: 'Created Job', label: 'prod'});
          return;  

        }
  };

  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    })
  }

  handleCancel = e => {
    e.preventDefault();
    ReactGA.event({category: 'Installer', action: 'Cancel Estimate', label: 'prod'});
    this.props.history.push("/requestimates");
    return;
  }

  handleNotes(notes) {
    this.setState({notes})
  }

  handleServices(services,total, discount) {
    this.setState({services , total, discount})
//    console.log ("Handle Services :", services, total, discount, duration)
  }


  handleCustomer(customer, customerName, customerType) {
    //    console.log("Handle Customer selection", customer);
        this.setState({customer, customerName, customerType});
      }
    

  handleVIN(vehicle) {
    console.log ("Update vehicle state", vehicle)
    this.setState({ vehicle })
  }

  handleDueDate(date) {
    this.setState({ dueDate: date })
  }

  handleAvailableDate(date) {
    this.setState({availableDate: date})
  }

  readAuth = async (installerId) => {
    let installerRequest = `/installerAuth/${installerId}`;        
    const  { data : installer }  =  await API.get(installerRequest);
  //  console.log("Installer :", installer);
    this.setState( {authenticated : installer[0].authenticated, auth : installer[0]});
  }

  // create a jumbo object of requestimate and vehicle and send it to a service
  addVehicle = async () => {
    const { vehicle } = this.state;
    const { data: newVehicleId } = await API.post(`/vehicles`, vehicle);
     return null;
  };

  addCalendar = async () => {
    let calendar = {
      requestId: this.state.newRequestId,
      installerId : this.props.typeId,
      installerCapacity : this.props.capacity
    }
    // take the work requested and create the draft calendar entries 
    const { data : newCalendar } = await API.post(`/schedule/`, calendar)
    
    return newCalendar;
    }

    addAppointment = async (appointment) => {
         let readAppt = `/readAppt/${appointment}`;        
         const { data: appt } = await API.get(readAppt);
     
     //    console.log("Here's the data for the appointment :", appt);
     //    console.log("description :", description);
        let calEntry = appt[0];
      console.log("AddAppt : auth object : ", this.state.auth);
        let summary = `${calEntry.Year} ${calEntry.Make} ${calEntry.Model} ${calEntry.Service_Requested}`;
         let newAppt = {
             request : appointment,
             summary : summary,
             calendar : this.state.auth.calendar_id,
             description : "",
             startDate : calEntry.work_date,
             duration : calEntry.request_duration,
             token : calEntry.refresh_token
         };
         console.log ("AddAppointment : Add to the calendar ", newAppt);
     
         let apptAdd = `/addAppt/`;        
         const { data: addAppt } = await API.put(apptAdd, newAppt);
         return;
     };
    

  addNote = async () => {
    let newNote = {
      requestId : this.state.newRequestId,
      noteDate : convertToUTC(new Date()).format("YYYY/MM/DD hh:mm:ss"),
      noteText : this.state.notes,
      userType : this.props.userType,
      userId : this.props.typeId
    }
//    if (!this.state.notes){
//      Promise.resolve(this.addNote)
//      return;
//    }
    const { data : newRequestNote } = await API.post("/requestnotes/", newNote)
    console.log ( newRequestNote);
    return newRequestNote;
  };


// For installer created dealer work, the request type should be set to "dealer" and the currentState set to "Assigned"

  addRequest = async () => {
    let newRequest = {
      requestType: this.props.userType,
      requestor: this.state.customer,
      serviceRequested: this.state.services[0].service_category + " " + this.state.services[0].description,
      requestTotal: this.state.total,
      requestFee: 0,
      requestDuration: 0.0,
      requestDiscount : 0,
      assignedTo: this.props.typeId,
      createdBy: this.props.typeId,
      availableDate:  convertToUTC(this.state.availableDate).format("YYYY/MM/DD HH:mm:ss"),
      dueDate: convertToUTC(this.state.availableDate).format("YYYY/MM/DD HH:mm:ss"),
      currentState: "new",
      createdDate: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"),
      vin: this.state.vehicle.vin,
      createdType : "installer",
      installerCatalog : this.props.typeId
    }

    // If this is a wholesale request entered by the installer 

      if (this.state.customerType === "whole") {
        newRequest.requestType = "dealer";
        newRequest.currentState = "scheduled";
      }
    // process the services requested array to create the right value
    newRequest.requestDuration = this.state.services.reduce(function(prev, cur) {
        return prev + cur.duration_days;
      }, 0); 
  
      if (this.state.services.length > 1) {
        newRequest.serviceRequested = newRequest.serviceRequested + " + " + (this.state.services.length-1);
      }
      newRequest.requestFee = newRequest.requestTotal * this.state.platformFee;
      
      if (this.state.discount > 0){
        newRequest.requestDiscount = this.state.discount;
        newRequest.requestTotal = newRequest.requestTotal - (newRequest.requestTotal * this.state.discount / 100);
      };

      if (newRequest.requestType === "dealer")
        newRequest.requestTaxes = 0;
      else
        newRequest.requestTaxes = newRequest.requestTotal * this.state.taxPercent/100;

  
    const { data: newRequestId } = await API.post("/requestimates/", newRequest);
    this.setState({ newRequestId });
    return newRequestId;
  }

  addServices = async () => {
    
    // const id = await requestId
    const newRequestService = {
      requestId: this.state.newRequestId,
      serviceId: "",
      serviceAmount: 0,
      serviceDiscount: 0,
      serviceDuration: 0,
      serviceFee: 0,
      serviceState: "new",
      serviceDate: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"),
      customDescription : "" // new moment().format("YYYY/MM/DD HH:mm:ss")
    }

    for (let i = 0; i < this.state.services.length; i++) {
        newRequestService.serviceId = this.state.services[i].service_id;
        newRequestService.customDescription = this.state.services[i].description;
        newRequestService.serviceAmount = this.state.services[i].service_cost;
        if (this.state.discount > 0){
          newRequestService.serviceDiscount = newRequestService.serviceAmount * this.state.discount/100;
        }
        newRequestService.serviceFee = newRequestService.serviceAmount * this.state.platformFee;
        newRequestService.serviceDuration = this.state.services[i].duration_days;
        await API.post("/requestService", newRequestService);
    }
    return null;
  }

  _next() {
    let currentStep = this.state.currentStep
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 4 ? 5 : currentStep + 1
    this.setState({ currentStep: currentStep })
  }

  _prev() {
    let currentStep = this.state.currentStep
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    this.setState({ currentStep: currentStep })
  }

  // The "next" and "previous" button functions
  get previousButton() {

    let currentStep = this.state.currentStep;

    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (<button className="btn btn-success buttons" type="button" onClick={this._prev}> PREV</button>)
    }

    // ...else return nothing
    return null;

  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 4, then render the "next" button
    if(currentStep < 5){
        let disable = true;
        if (currentStep === 1 && this.state.customer) {
          disable = false;
        }
        if (currentStep === 2 && this.state.vehicle) {
          disable = false;
        }
        if (currentStep === 3 && this.state.services.length > 0) {
          disable = false;
        }
        if (currentStep === 4) {
          disable = false;
        }
      return (
        <button 
          className="btn btn-success float-right buttons" 
          type="button" disabled={disable} onClick={this._next}>
        NEXT
        </button>        
      )
    } else {
      // ... must be the submit time...
      return (
        <button 
          className="btn btn-primary float-right" 
          type="button" onClick={this.handleSubmit}>
                            {this.state.loading && <span>
                                    <i
                                    className="fa fa-refresh fa-spin"
                                    />
                                </span>}
                                {!this.state.loading && <span>Create Estimate</span>}

        </button>        
      ) 
    }

  };

  get steepOne () {
    if (!this.state.customer) {
      return (
        <li className="nav-item">
          <div className="col-sm-12 p-0">
            <button className="btn btn-outline-secondary btn-circle bg-white">
            </button>
            <br />
            Customer
            <div className="line-50-right"></div>
          </div>
        </li>
      );
    }
    return (
      <li className="nav-item">
        <div className="col-sm-12 p-0">
          <button className="btn btn-outline-success btn-circle bg-white">
            <i className="fa fa-check"></i>
          </button>
          <br />
          Customer
          <div className="line-50-right"></div>
        </div>
      </li>
    );
  };
  get steepTwo () {
    if (!this.state.vehicle) {
      return (
        <li className="nav-item">
          <div className="col-sm-12 p-0">
            <button className="btn btn-outline-secondary btn-circle bg-white">
            </button>
            <br />
            Vehicle
            <div className="line-100"></div>
          </div>
        </li>
      );
    }
    return (
      <li className="nav-item">
        <div className="col-sm-12 p-0">
          <button className="btn btn-outline-success btn-circle bg-white">
            <i className="fa fa-check"></i>
          </button>
          <br />
          Vehicle
          <div className="line-100"></div>
        </div>
      </li>
    );
  };
  get steepThree () {
    if (this.state.services < 1) {
      return (
        <li className="nav-item">
          <div className="col-sm-12 p-0">
            <button className="btn btn-outline-secondary btn-circle bg-white"></button>
            <br />
            Work
            <div className="line-100"></div>
          </div>
        </li>
      );
    }
    return (
      <li className="nav-item">
        <div className="col-sm-12 p-0">
          <button className="btn btn-outline-success btn-circle bg-white">
            <i className="fa fa-check"></i>
          </button>
          <br />
          Work
          <div className="line-100"></div>
        </div>
      </li>
    );
  };
  get steepFour () {
    if (!this.state.notes) {
      return (
        <li className="nav-item">
          <div className="col-sm-12 p-0">
            <button className="btn btn-outline-secondary btn-circle bg-white"></button>
            <br />
            Notes
            <div className="line-100"></div>
          </div>
        </li>
      );
    }
    return (
      <li className="nav-item">
        <div className="col-sm-12 p-0">
          <button className="btn btn-outline-success btn-circle bg-white">
            <i className="fa fa-check"></i>
          </button>
          <br />
          Notes
          <div className="line-100"></div>
        </div>
      </li>
    );
  };
  get steepFive () {
    if (this.state.currentStep !== 5 && this.state.dueDate) {
      return (
        <li className="nav-item">
          <div className="col-sm-12 p-0">
            <button className="btn btn-outline-secondary btn-circle bg-white"></button>
            <br />
            Dates
            <div className="line-50-left"></div>
          </div>
        </li>
      );
    }
    return (
      <li className="nav-item">
        <div className="col-sm-12 p-0">
          <button className="btn btn-outline-success btn-circle bg-white">
            <i className="fa fa-check"></i>
          </button>
          <br />
          Dates
          <div className="line-50-left"></div>
        </div>
      </li>
    );
  };


  // This is also where when all the validation is completed, we push the new record
  // and redirect the page to the requestimate form with the parameters set
  render() {
    return (
      <React.Fragment>
        <div className="bg-white border border-secondary">
          <div className="col-lg-12 text-right">
            <button className="btn" type="button" onClick={this.handleCancel}>
              <i className="fa fa-times"></i>
            </button>
          </div>

          <div className="col-lg-12 d-flex justify-content-center">
            <ul className="col-lg-5 nav nav-pills nav-fill text-center">
              {this.steepOne}
              {this.steepTwo}
              {this.steepThree}
              {this.steepFour}
              {this.steepFive}
            </ul>
          </div>
          <br />
          <br />
          <br />
          <div className="col-lg-12 p-0">
            <CustomerForm 
                currentStep={this.state.currentStep} 
                userType = "installer"
                vehicle={this.state.vehicle}
                availableDate = {this.state.availableDate}
                dueDate = {this.state.dueDate}
                services={this.state.services}
                notes = {this.state.notes}
                dealerships = {this.state.dealerships}
                handleCustomer={this.handleCustomer}
                installer={this.state.installer}
                mode={"search"}
                />       
            <VehicleSelectForm
              currentStep={this.state.currentStep}
              handleVIN={this.handleVIN}
              notes = {this.state.notes}
              default = {""}
              customer = {this.state.customer}
              installer = {this.state.installer}
              customerType= {this.state.customerType}
              userType={"installer"}
              vehicle={this.state.vehicle} />
            <ServiceForm
                userType={this.props.userType}
                typeId={this.props.typeId}
                currentStep={this.state.currentStep} 
                handleServices={this.handleServices}
                total={this.state.total}
                customerType= {this.state.customerType}
                vehicle={this.state.vehicle}
                discount={this.state.discount}
                services={this.state.services}
                notes = {this.state.notes}/>
            <NotesForm 
                currentStep={this.state.currentStep} 
                vehicle={this.state.vehicle}
                userType = "installer"
                availableDate = {this.state.availableDate}
                dueDate = {this.state.dueDate}
                services={this.state.services}
                notes = {this.state.notes}
                handleNotes={this.handleNotes}
                />       
            <DatesForm
                userType={this.props.userType}
                typeId={this.props.typeId}
                currentStep={this.state.currentStep} 
                handleAvailableDate={this.handleAvailableDate}
                availableDate={this.state.availableDate}
                vehicle={this.state.vehicle}
                services={this.state.services}
                notes={this.state.notes}
                discount={this.state.discount}
                customer={this.state.customerName}
                excludeDays={this.state.excludeDays}
            />


            <div className="m-5 d-flex justify-content-center">
              {/* <pre>{this.state.vehicle}</pre> */}
              <div className="p-2">
                {this.previousButton}
              </div>
              <div className="p-2">
                {this.nextButton}
              </div>
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  };

}
export default withRouter(WalkinForm);
