// This will be the header unit for the wizard layout.
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import moment from 'moment';
import SpecialistForm from './../../specialistForm';
import VehicleForm from './../../vehicleSelection';
import ServiceForm from './../../serviceForm';
import NotesForm from './../../notesForm';
import DatesForm from './../../datesForm';
import { HTTPService } from "./../../../services/httpService";
import { convertToUTC } from '../../../util/convert_timezone';
import { isIOS } from 'react-device-detect';
import ReactGA from 'react-ga4';

const API = new HTTPService();

class RequestimateForm extends Component {

  constructor(props) {

    super(props);

    this.state = {
      redirect: false,
      currentStep: 1,
      platformFee : 0.1,
      customer: this.props.typeId,
      vehicleID: '',
      services: [],
      total : 0,
      discount : 0,
      taxRate : 0,
      duration : 0,
      fee : 0,
      loading:false,
      notes:"",
      availableDate: new moment().startOf('day'),
      dueDate: new moment().startOf('day'),
      newVehicleId: {},
      newRequestId: {},
      user: this.props.user,
      companyName: this.props.companyName,
      company: this.props.company,
      specialist : "",
      specialists :[],
    };
    
    this.handleChange = this.handleChange.bind(this);
    this.handleSpecialist = this.handleSpecialist.bind(this);
    this.handleServices = this.handleServices.bind(this);
    this.handleVIN = this.handleVIN.bind(this);
    this.handleNotes = this.handleNotes.bind(this);
    this.handleDueDate = this.handleDueDate.bind(this);
    this.handleAvailableDate = this.handleAvailableDate.bind(this);
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);

  }

  async componentDidMount() {
    const specialistRequest =  "/installerProximity/"+this.props.company.dealer_id;
    const { data : specialists } = await API.get(specialistRequest);
    console.log("SpecialistForm: ", specialists);
    this.setState( { specialists });
  };

  handleSubmit = async e => {
    e.preventDefault();
// add the vehicle to the vehicle table
// need vin, {year, make, model & trim} for Vehicle in Props
  this.setState({ loading: true });

//   console.log ("Here is the submission process");

   const newVehicle = await this.addVehicle();
//   console.log("New vehicle Added is :" + newVehicle);
   
// add the requestimate to the request table 
    const newRequest = await this.addRequest();

// add the services records
// need the request number from the previous call to make this work.
// same thing with vehicle

      await this.addNote(newRequest);

      const newServices = await this.addServices(newRequest);
// add the notes here too, as you need the requestId


      const findInstaller = await this.findInstallers(newRequest);
      console.log("requestimateForm : found installers :", findInstaller);
      this.setState({ loading: false });

      console.log ("RequestimateForm : selected Specialist :", this.state.specialist);

        if ( this.state.specialist !== 0 ){ 
            {  let data = {
                messageType : "User",
                messagePlatform : "SMS",
                userType : "installer",
                id : this.state.specialist,
                text : `New work request from ${this.state.companyName}. https://jobvious.app/requestimates`
              }    
              await this.sendMessage(data);
            }
        }
        await this.sendRequestEmail(newRequest);
        if (this.props.company.customer_contract === "N"){
          alert( "We'll take it from here") 
          ReactGA.event({category: 'Dealer', action: 'Created Request', label: 'prod'});
          window.location = "/requestimates";  
        } else {
          alert( "Let's create the customer contract") 
          ReactGA.event({category: 'Dealer', action: 'Created Request', label: 'prod'});
          window.location = "/contract/"+newRequest;  
        }
        return;  
      };

    
  // Use the submitted data to set the state
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    })
  }


  sendMessage = async (data)  => {
    console.log ("Sending SMS :", data);
    await API.post("/messages/push", data);
    return;
  }

  handleCancel = e => {
    e.preventDefault();
    ReactGA.event({category: 'Dealer', action: 'Cancel Creating Request', label: 'prod'});
    this.props.history.push("/requestimates");
    return;
  }


  addDays(date, days) {
    let result = moment(date).format("YYYY/MM/DD");
    let results = moment(result).add(days, "days");
    return results;
  }

  handleNotes(notes) {
    this.setState({notes})
  }

  handleServices(services,total, discount) {
    let duration = services.reduce(function (accumulator, currentValue){ return accumulator + currentValue.duration_days}, 0);
    // update the due date based on the duration of the services
    let diff = 1;
    if(this.state.duration > 1){
      diff = Math.round(this.state.duration);
    }
    let dueDate = this.addDays(this.state.availableDate,diff).toDate();

    this.setState({services , total, discount, duration, dueDate})
//    console.log ("Handle Services :", services, total, discount, duration)
  }

  handleSpecialist(specialist) {
    console.log ("requestimateForm : handle specialist ", specialist)
    this.setState({ specialist })
  }

  handleVIN(vehicle) {
    console.log ("Update vehicle state", vehicle)
    this.setState({ vehicle })
  }

  handleDueDate(date) {
    this.setState({ dueDate: date })
  }

  handleAvailableDate(date) {
    let diff = 1;
    if(this.state.duration > 1){
      diff = Math.round(this.state.duration);
    } 
    let dueDate = this.addDays(date,diff).toDate();
    this.setState({availableDate: date, dueDate})
  }

  // create a jumbo object of requestimate and vehicle and send it to a service
  addVehicle = async () => {
    const { vehicle } = this.state;

    const { data: newVehicleId } = await API.get(`/vehicles/${vehicle.vin}`);

    if (newVehicleId.length === 0) {
      const { data: newVehicleId } = await API.post(`/vehicles`, vehicle);
      this.setState({ newVehicleId });
    } else {
      this.setState({ newVehicleId });
    }

//    this.props.history.push('/requestimates');
     return null;
  };

  findInstallers = async (requestId) => {
    let find = "";
    if (this.state.specialist === "0") {
      find = "/matchAll/?Id="
    } else {
    find = "/matchA/?Id="
    }
    const { data : matchInstallers } = await API.get(find + requestId)

    return matchInstallers;
  }

  getInstallers = async (requestId) => {
    let responseRequest = `/requestoffered/request/${requestId}`;

    const { data: tempRequestOffers } = await API.get(responseRequest);
    return tempRequestOffers;
  }

  sendRequestEmail = async (requestId) => {
    let responseRequest = `/sendemailreq/${requestId}`;

    const { data: tempRequestOffers } = await API.get(responseRequest);
    return tempRequestOffers;
  }

  addNote = async (requestId) => {
    let newNote = {
      requestId : await requestId,
      noteDate : convertToUTC(new Date()).format("YYYY/MM/DD hh:mm:ss"),
      noteText : this.state.notes,
      userType : this.props.userType,
      userId : this.props.typeId
    }
    if (!this.state.notes){
      Promise.resolve(this.addNote)
      return;
    }
    const { data : newRequestNote } = await API.post("/requestnotes/", newNote)
    console.log ( newRequestNote);
    return newRequestNote;
  };


  addRequest = async () => {
    let newRequest = {
      requestType: this.props.userType,
      requestor: this.state.company.dealer_id,
      serviceRequested: this.state.services[0].service_category + " " + this.state.services[0].description,
      requestTotal: this.state.total,
      requestDiscount : 0,
      requestFee: 0,
      requestDuration: 0.0,
      requestTaxes : 0,
      assignedTo: this.state.specialist,
      createdBy: this.props.typeId,
      // createdBy: this.state.company.dealer_id,
      availableDate:  convertToUTC(this.state.availableDate).format("YYYY/MM/DD HH:mm:ss"),
      dueDate: convertToUTC(this.state.dueDate).format("YYYY/MM/DD HH:mm:ss"),
      currentState: "new",
      createdDate: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"),
      vin: this.state.vehicle.vin,
      createdType : "dealer",
      installerCatalog : this.state.specialist,
    }
    // process the services requested array to create the right value
    if (this.state.services.length > 1) {
      newRequest.serviceRequested = newRequest.serviceRequested + " + " + (this.state.services.length - 1);
    }

    newRequest.requestDuration = this.state.services.reduce(function(prev, cur) {
      return prev + cur.duration_days;
    }, 0); 

    newRequest.requestFee = newRequest.requestTotal * this.state.platformFee;
    
    const { data: newRequestId } = await API.post("/requestimates/", newRequest);
    this.setState({ newRequestId });
    return newRequestId;
  }

  addServices = async (requestId) => {
    
    // const id = await requestId
    const newRequestService = {
      requestId: await requestId,
      serviceId: "",
      serviceAmount: 0,
      serviceDiscount: 0,
      serviceDuration: 0,
      serviceFee: 0,
      serviceState: "new",
      serviceDate: convertToUTC(new Date()).format("YYYY/MM/DD HH:mm:ss"), // new moment().format("YYYY/MM/DD HH:mm:ss")}
      customDescription :""
    }
    for (let i = 0; i < this.state.services.length; i++) {
      newRequestService.serviceId = this.state.services[i].service_id;
      newRequestService.serviceAmount = this.state.services[i].service_cost;
      newRequestService.serviceDuration = this.state.services[i].duration_days;
      newRequestService.serviceFee = this.state.services[i].service_cost * this.state.platformFee;
      newRequestService.customDescription = this.state.services[i].description;
      await API.post("/requestService", newRequestService);
    }
    return null;
  }

  _next() {
    let currentStep = this.state.currentStep
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 4 ? 5 : currentStep + 1
    this.setState({ currentStep: currentStep })
  }

  _prev() {
    let currentStep = this.state.currentStep
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1
    this.setState({ currentStep: currentStep })
  }

  // The "next" and "previous" button functions
  get previousButton() {

    let currentStep = this.state.currentStep;

    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (<button className="btn btn-success" type="button" onClick={this._prev}> PREV</button>)
    }

    // ...else return nothing
    return null;

  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 4, then render the "next" button
    if(currentStep < 5){
      let disabled = true;
      if (currentStep === 1 && this.state.specialist) {
        disabled = false;
      }
      if (currentStep === 2 && this.state.vehicle) {
        disabled = false;
      }
      if (currentStep === 3 && this.state.services.length > 0) {
        disabled = false;
      }
      if (currentStep === 4) {
        disabled = false;
      }
      return (
        <button 
          className="btn btn-success float-right" 
          type="button" disabled={disabled} onClick={this._next}>
        NEXT
        </button>        
      )
    } else {
      // ... must be the submit time...
      return (
        <button 
          className="btn btn-primary float-right" 
          type="button" onClick={this.handleSubmit}>
                            {this.state.loading && <span>
                                    <i
                                    className="fa fa-refresh fa-spin"
                                    />
                                </span>}
                                {!this.state.loading && <span>Send Request</span>}

        </button>        
      ) 
    }

  };


  get steepOne () {
    if (!this.state.specialist) {
      return (
        <li className="nav-item">
          <div className="col-sm-12 p-0">
            <button className="btn btn-outline-secondary btn-circle bg-white">
            </button>
            <br />
            Specialist
            <div className="line-50-right"></div>
          </div>
        </li>
      );
    }
    return (
      <li className="nav-item">
        <div className="col-sm-12 p-0">
          <button className="btn btn-outline-success btn-circle bg-white">
            <i className="fa fa-check"></i>
          </button>
          <br />
          Specialist
          <div className="line-50-right"></div>
        </div>
      </li>
    );
  };

  get steepTwo () {
    if (!this.state.vehicle) {
      return (
        <li className="nav-item">
          <div className="col-sm-12 p-0">
            <button className="btn btn-outline-secondary btn-circle bg-white">
            </button>
            <br />
            Vehicle
            <div className="line-100"></div>
          </div>
        </li>
      );
    }
    return (
      <li className="nav-item">
        <div className="col-sm-12 p-0">
          <button className="btn btn-outline-success btn-circle bg-white">
            <i className="fa fa-check"></i>
          </button>
          <br />
          Vehicle
          <div className="line-100"></div>
        </div>
      </li>
    );
  };
  get steepThree () {
    if (this.state.services < 1) {
      return (
        <li className="nav-item">
          <div className="col-sm-12 p-0">
            <button className="btn btn-outline-secondary btn-circle bg-white"></button>
            <br />
            Work
            <div className="line-100"></div>
          </div>
        </li>
      );
    }
    return (
      <li className="nav-item">
        <div className="col-sm-12 p-0">
          <button className="btn btn-outline-success btn-circle bg-white">
            <i className="fa fa-check"></i>
          </button>
          <br />
          Work
          <div className="line-100"></div>
        </div>
      </li>
    );
  };

  get steepFour () {
    if (!this.state.notes) {
      return (
        <li className="nav-item">
          <div className="col-sm-12 p-0">
            <button className="btn btn-outline-secondary btn-circle bg-white"></button>
            <br />
            Notes
            <div className="line-100"></div>
          </div>
        </li>
      );
    }
    return (
      <li className="nav-item">
        <div className="col-sm-12 p-0">
          <button className="btn btn-outline-success btn-circle bg-white">
            <i className="fa fa-check"></i>
          </button>
          <br />
          Notes
          <div className="line-100"></div>
        </div>
      </li>
    );
  };

  get steepFive () {
    if (this.state.currentStep !== 5 && this.state.dueDate) {
      return (
        <li className="nav-item">
          <div className="col-sm-12 p-0">
            <button className="btn btn-outline-secondary btn-circle bg-white"></button>
            <br />
            Dates
            <div className="line-50-left"></div>
          </div>
        </li>
      );
    }
    return (
      <li className="nav-item">
        <div className="col-sm-12 p-0">
          <button className="btn btn-outline-success btn-circle bg-white">
            <i className="fa fa-check"></i>
          </button>
          <br />
          Dates
          <div className="line-50-left"></div>
        </div>
      </li>
    );
  };



  // This is also where when all the validation is completed, we push the new record
  // and redirect the page to the requestimate form with the parameters set
  render() {

    console.log ("Requestimate form : Company Contract : ", this.props.company);
    return (
      <React.Fragment>
        <div className="bg-white border border-secondary">
          <div className="col-lg-12 text-right">
            <button className="btn" type="button" onClick={this.handleCancel}>
              <i className="fa fa-times"></i>
            </button>
          </div>

          <div className="col-lg-12 d-flex justify-content-center">
            <ul className="col-lg-5 nav nav-pills nav-fill text-center">
              {this.steepOne}
              {this.steepTwo}
              {this.steepThree}
              {this.steepFour}
              {this.steepFive}
            </ul>
          </div>
          <br />
          <br />
          <br />
          <div className="col-lg-12 p-0">
            
          <SpecialistForm
              currentStep={this.state.currentStep}
              selectedSpecialist = {this.state.specialist}
              handleSpecialist={this.handleSpecialist}
              specialists={this.state.specialists} />

            <VehicleForm
              currentStep={this.state.currentStep}
              handleVIN={this.handleVIN}
              notes = {this.state.notes}
              default={this.props.company.main_make}
              userType= {"dealer"}
              customerType={"whole"}
              vehicle={this.state.vehicle} />

            <ServiceForm
                userType={this.props.userType}
                typeId={this.props.typeId}
                currentStep={this.state.currentStep} 
                handleServices={this.handleServices}
                specialist = {this.state.specialist}
                vehicle={this.state.vehicle}
                total={this.state.total}
                discount={this.state.discount}
                services={this.state.services}
                notes = {this.state.notes}/>
              <NotesForm 
                currentStep={this.state.currentStep} 
                vehicle={this.state.vehicle}
                userType = "dealer"
                availableDate = {this.state.availableDate}
                dueDate = {this.state.dueDate}
                services={this.state.services}
                notes = {this.state.notes}
                handleNotes={this.handleNotes}
                />       
            <DatesForm
              userType={this.props.userType}
              currentStep={this.state.currentStep}
              handleAvailableDate={this.handleAvailableDate}
              handleDueDate={this.handleDueDate}
              availableDate={this.state.availableDate}
              dueDate={this.state.dueDate}
              duration={this.state.duration}
              vehicle={this.state.vehicle}
              notes={this.state.notes}
              services={this.state.services}
              specialist={this.state.specialist}

            />

            <div className="m-5 d-flex justify-content-center">
              {/* <pre>{this.state.vehicle}</pre> */}
              <div className="p-2">
                {this.previousButton}
              </div>
              <div className="p-2">
                {this.nextButton}
              </div>
            </div>

          </div>
        </div>
      </React.Fragment>
    );
  };

}
export default withRouter(RequestimateForm);
